import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const NotFound = () => {
  const bg = useColorModeValue("orange.50", "gray.900");
  const textColor = useColorModeValue("gray.800", "orange.200");

  return (
    <Box
      textAlign="center"
      py={10}
      px={6}
      bg={bg}
      color={textColor}
      fontFamily="Comic Sans MS, cursive, sans-serif"
    >
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, orange.400, orange.500, orange.600)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text color={textColor} mb={6}>
        The page you're looking for does not seem to exist.
      </Text>
      <Button
        as={Link}
        to="/"
        colorScheme="orange"
        bgGradient="linear(to-r, orange.400, orange.500, orange.600)"
        color="white"
        variant="solid"
        fontFamily="Comic Sans MS, cursive, sans-serif"
      >
        Go to Home
      </Button>
    </Box>
  );
};

export default NotFound;
