import React from "react";
import {
  Box,
  Flex,
  Link,
  IconButton,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  Stack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, SunIcon, MoonIcon } from "@chakra-ui/icons";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import monkeyLogo from "../assets/monkey.png";

const NavBar = () => {
  const { toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bg = useColorModeValue("orange.100", "gray.800");
  const color = useColorModeValue("gray.700", "orange.300");
  const linkHoverBg = useColorModeValue("orange.200", "gray.700");

  const socialMediaLinks = [
    {
      icon: FaTelegramPlane,
      href: "https://t.co/GQ4gh8c1WW",
      label: "Telegram",
    },
    {
      icon: FaTwitter,
      href: "https://x.com/hooraymonkey",
      label: "Twitter",
    },
  ];

  return (
    <Box bg={bg} px={4} boxShadow="sm" >
      <Flex h={20} alignItems="center" justifyContent="space-between">
        <Box
          fontWeight="bold"
          fontSize="xl"
          fontFamily="'Courier New', cursive, sans-serif"
        >
          <Image
            src={monkeyLogo}
            alt="Backpack Monkey"
            boxSize="60px"
            borderRadius="full"
            border="2px"
            borderColor={color}
          />
        </Box>
        <Flex
          display={{ base: "none", md: "flex" }}
          alignItems="center"
          gap={6}
        >
          {" "}
          {socialMediaLinks.map((item) => (
            <IconButton
              key={item.label}
              as="a"
              href={item.href}
              aria-label={item.label}
              icon={<item.icon />}
              variant="ghost"
              color={color}
              _hover={{ bg: linkHoverBg }}
              fontSize="2xl"
              isRound
              target="_blank"
              rel="noopener noreferrer"
            />
          ))}
          <IconButton
            aria-label="Toggle Color Mode"
            icon={useColorModeValue(<MoonIcon />, <SunIcon />)}
            ml={4}
            onClick={toggleColorMode}
            color={color}
            fontSize="2xl"
          />
        </Flex>
        <IconButton
          size="md"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label="Open Menu"
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
          color={color}
          fontSize="2xl"
        />
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg={bg}>
          <DrawerCloseButton />
          <DrawerHeader fontFamily="'Courier New', cursive, sans-serif">
            Navigation
          </DrawerHeader>
          <DrawerBody>
            <Stack as="nav" spacing={4}>
              {socialMediaLinks.map((item) => (
                <Link
                  key={item.label}
                  px={2}
                  py={1}
                  rounded="md"
                  _hover={{ textDecoration: "none", bg: linkHoverBg }}
                  href={item.href}
                  onClick={onClose}
                  color={color}
                  fontFamily="'Courier New', cursive, sans-serif"
                  display="flex"
                  alignItems="center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <item.icon style={{ marginRight: "8px", fontSize: "2xl" }} />
                  {item.label}
                </Link>
              ))}
              <IconButton
                aria-label="Toggle Color Mode"
                icon={useColorModeValue(<MoonIcon />, <SunIcon />)}
                mt={4}
                onClick={toggleColorMode}
                color={color}
                fontSize="2xl"
              />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default NavBar;
