import React, { useState, useCallback } from "react";
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  Button,
  keyframes,
} from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import monkeyImage from "../assets/monkey.png"; // Original monkey image
import spaceMonkey from "../assets/spaceMonkey.png"; // Space monkey image
import dexscreener from "../assets/dexscreener.png";
import dextools from "../assets/dextools.png";
import meme from "../assets/meme.jpg"; // Import the meme image

const MotionBox = motion(Box);
const MotionImage = motion(Image);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionButton = motion(Button);

// Keyframe animation for continuous pulsing effect
const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(0, 0, 255, 0.5);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(0, 0, 255, 0.5);
  }
`;

// Swinging animation for the ticker symbol
const swingAnimation = {
  initial: { rotate: 0 },
  animate: {
    rotate: [0, 15, -15, 15, -15, 0], // Rotation values for swinging
    transition: {
      duration: 2,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "loop",
    },
  },
};

const Home = () => {
  const bg = useColorModeValue("orange.50", "gray.900");
  const textColor = useColorModeValue("gray.800", "orange.200");
  const boxBg = useColorModeValue("orange.50", "gray.900");
  const navFooterBg = useColorModeValue("orange.100", "gray.800");
  const [isBlastingOff, setIsBlastingOff] = useState(false);

  const controls = useAnimation();

  const handleBlastOff = useCallback(async () => {
    if (isBlastingOff) return; // Prevents multiple clicks
    setIsBlastingOff(true);
    await controls.start({
      y: -1000,
      scale: 2,
      transition: { duration: 3, ease: "easeInOut" },
    });
    await controls.start({ y: 0, scale: 1, transition: { duration: 0 } }); // Reset to original state
    setIsBlastingOff(false);
  }, [isBlastingOff, controls]);

  return (
    <Flex
      direction="column"
      minHeight="100vh"
      bg={bg}
      color={textColor}
      fontFamily="'Courier New', cursive, sans-serif"
    >
      <Box flex="1">
        <MotionBox
          id="home"
          textAlign="center"
          p={4}
          initial="initial"
          animate="animate"
        >
          <VStack spacing={8} mt={8}>
            <MotionHeading
              as="h1"
              size="2xl"
              initial={{ y: -50 }}
              animate={{ y: 0 }}
              transition={{ duration: 1 }}
              fontFamily="'Courier New', cursive, sans-serif"
            >
              Backpack Monkey
            </MotionHeading>
            <MotionHeading
              size="xl"
              initial="initial"
              animate="animate"
              variants={swingAnimation} // Apply the swinging animation
              fontFamily="'Courier New', cursive, sans-serif"
            >
              $hooray
            </MotionHeading>
            <MotionImage
              src={isBlastingOff ? spaceMonkey : monkeyImage}
              alt="Backpack Monkey"
              boxSize={{ base: "150px", md: "200px" }}
              initial={{ scale: 1 }}
              animate={controls}
            />
            <MotionText
              fontSize="xl"
              fontWeight="bold"
              maxW={{ base: "90%", md: "100%" }}
              mx="auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1.5 }}
              wordBreak="break-all"
            >
              CA: B8rhn9fer7GmivNsXVuKbgW6iGNKfvBgNGDdg7x25zC2
            </MotionText>
            <HStack spacing={4}>
              <MotionButton
                colorScheme="red" // Changed to red color
                size="lg"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={handleBlastOff}
                isDisabled={isBlastingOff} // Disable button during animation
              >
                Blast Off!
              </MotionButton>
              <Button
                colorScheme="green"
                size="lg"
                as="a"
                href="https://example.com/buy" // Replace with the actual URL
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy Now
              </Button>
            </HStack>
            <HStack spacing={4}>
              <a
                href="https://example.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MotionImage
                  src={dexscreener}
                  alt="Dexscreener"
                  boxSize={{ base: "80px", md: "100px" }}
                  whileHover={{ scale: 1.1 }}
                />
              </a>
              <a
                href="https://example.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MotionImage
                  src={dextools}
                  alt="Dextools"
                  boxSize={{ base: "80px", md: "100px" }}
                  whileHover={{ scale: 1.1 }}
                />
              </a>
            </HStack>
          </VStack>
        </MotionBox>
        <MotionBox
          textAlign="center"
          p={4}
          id="about"
          bg={boxBg}
          m={4}
          maxW="600px"
          mx="auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          <Text fontSize="2xl">
            This monkey means business! He's here to take over. Enough with the
            dogs and cats, it's monkey-time. Ape in and come vibe with this
            backpack monkey, its #MonkeySeason!
          </Text>
        </MotionBox>
        <Flex
          justifyContent="center"
          alignItems="center"
          direction="column"
          mb={4}
        >
          <Box position="relative" maxW="600px" w="100%">
            <MotionImage
              src={meme}
              alt="Meme"
              maxWidth="100%" // Ensures the image retains its proportions
              height="auto"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            />
            <Flex
              justifyContent="center"
              alignItems="center"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
            >
              <MotionButton
                colorScheme="blue"
                size="lg"
                as="a"
                href="https://example.com/community" // Replace with the actual URL
                target="_blank"
                rel="noopener noreferrer"
                animation={`${pulse} 2s infinite`}
              >
                Join the Community
              </MotionButton>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <MotionBox
        as="footer"
        p={4}
        textAlign="center"
        bg={navFooterBg}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1.5 }}
              fontSize="l"
        fontFamily="inherit" // Ensure footer font matches
      >
        <Text>&copy; 2024 Backpack Monkey. All rights reserved.</Text>
      </MotionBox>
    </Flex>
  );
};

export default Home;
